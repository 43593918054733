import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faHome,
  faLocation,
  faLocationDot,
  faPhone,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

function Footer() {
  const formSDT1 = useRef();
  const sendSDT = (e) => {
    if (formSDT1.current.phone.value.toString().length > 9) {
      emailjs
        .sendForm(
          "service_fihg5vx",
          "template_j62qkpn",
          formSDT1.current,
          "tt8EBINZkDg6uPMLi"
        )
        .then(
          () => {
            // navigate("/dangkythanhcong")
            toast(`Đăng ký thành công`, {
              type: "success",
              autoClose: 1000,
              style: { marginTop: "50px" },
            });
            formSDT1.current.phone.value = "";
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      toast(`Vui lòng nhập số điện thoại đúng định dạng`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
    // e.target.reset()
  };
  return (
    <footer>
      <div className="main-footer">
        <div className="container">
          <Row>
            <div className="row footer-top clearfix">
              <div className="col-md-3 col-sm-12 d-flex align-items-center">
                <h2>Bạn cần thêm thông tin?</h2>
              </div>

              <div className="col-md-6 col-sm-12 mb-2">
                <Form ref={formSDT1} onSubmit={() => sendSDT()}>
                  <Row>
                    <Col xs={8}>
                      <Form.Floating>
                        <Form.Control
                          style={{ borderRadius: "50px" }}
                          type="text"
                          name="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label
                          for="floatingInputClassName"
                          style={{ marginLeft: "10px" }}
                        >
                          Nhập số điện thoại...<i className="text-danger"></i>
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                      </Form.Floating>
                      {/* <div className="form-sh_sdt">
                                                    <input type="text" placeholder="Nhập số điện thoại..." name='phone' />
                                                </div> */}
                    </Col>

                    <Col xs={4}>
                      <Button
                        className="text-center"
                        style={{
                          border: "0px",
                          borderRadius: " 50px",
                          whiteSpace: "nowrap",
                          height: "100%",
                        }}
                        onClick={() => sendSDT()}
                      >
                        {" "}
                        Đăng ký tư vấn
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>

              <div className="col-md-3 col-sm-12">
                <div className="help-box-f">
                  <h4>
                    Gọi hotline{" "}
                    <a href="tel:+84889881010" className="text-dark">
                      0889 88 1010
                    </a>
                  </h4>
                  <p>Để được tư vấn và hỗ trợ miễn phí 24/7.</p>
                </div>
              </div>
            </div>

            <div className="row footer-link-box clearfix">
              <Col md={8} sm={6}>
                <div className="row left-f-box">
                  <div className="col-sm-6">
                    <h2>CÔNG TY CỔ PHẦN CÔNG NGHỆ KATEC</h2>
                    <ul>
                      <li>
                        <a href="#">
                          <FontAwesomeIcon icon={faHome} />
                          &nbsp; Số 189, đường Phan Huy Chú, phường An Khánh,
                          quận Ninh Kiều, thành phố Cần Thơ.
                        </a>
                      </li>
                      <li>
                        <a href="tel:+84889881010" target="_blank">
                          <FontAwesomeIcon icon={faPhone} />
                          &nbsp; 0889 88 1010
                        </a>
                      </li>
                      <li>
                        <a href="pricing.html" target="_blank">
                          <FontAwesomeIcon icon={faEnvelope} />
                          &nbsp; katec.cantho@gmail.com
                        </a>
                      </li>
                      <li>
                        <a href="#">MST: 1801633969</a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-6">
                    <h2>HƯỚNG DẪN KHÁCH HÀNG</h2>
                    <ul>
                      <li>
                        <a
                          href="https://katec.vn/phuong-thuc-van-chuyen"
                          target="_blank"
                        >
                          Phương thức vận chuyển
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://katec.vn/huong-dan-mua-hang-thanh-toan"
                          target="_blank"
                        >
                          Hướng dẫn mua hàng, thanh toán
                        </a>
                      </li>
                      <li>
                        <a href="https://katec.vn/chinh-sach" target="_blank">
                          Chính sách bảo hành
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://katec.vn/chinh-sach-dai-ly"
                          target="_blank"
                        >
                          Chính sách đại lý
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <div className="col-md-4 col-sm-6">
                <div className="row right-f-box">
                  <h2>
                    <FontAwesomeIcon icon={faLocationDot} /> GOOGLE MAP
                  </h2>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.706498664805!2d105.75135877421363!3d10.041060572328492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a0886c3ecc3e01%3A0x5a84e770728f1669!2zMTg5IMSQLiBQaGFuIEh1eSBDaMO6LCBQaMaw4budbmcgQW4gS2jDoW5oLCBOaW5oIEtp4buBdSwgQ-G6p24gVGjGoSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1683166794218!5m2!1svi!2s"
                    width="150"
                    height="145"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </Row>
          {/* </Form> */}
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <p>
                Copyright © 2023 by{" "}
                <a href="https://katec.vn/" target="_blank">
                  {" "}
                  Katec
                </a>
                .
              </p>
            </div>
            {/* <div className="col-md-4">
                            <ul className="list-inline socials">
                                <li className='list-inline-item px-1'>
                                    <a href="">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </li>
                                <li className='list-inline-item px-1'>
                                    <a href="">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                </li>
                                <li className='list-inline-item px-1'>
                                    <a href="">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </li>
                                <li className='list-inline-item px-1'>
                                    <a href="#">
                                        <i className="fw-bold" >Zalo</i>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
