import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faDatabase,
  faLaptop,
  faMoneyCheckDollar,
  faStar,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";
import "swiper/css";
import "swiper/css/free-mode";

function HomeIndex() {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [showimg, setshowimg] = useState(false);
  const [to_day, setTo_day] = useState(new Date());
  const [current_day, setCurrent_day] = useState(new Date());
  const [data, setdataa] = useState([]);
  const doanhnghiep = [
    {
      image: "assets/user/images/product/antho.jpg",
      lable: "Mầm Non An Thơ",
      link: "https://mamnonantho.edu.vn",
    },
    {
      image: "assets/user/images/product/Web_Viettel.png",
      lable: "Viettel Cần Thơ",
      link: "https://viettel.cantho.vn",
    },
    {
      image: "assets/user/images/product/ctybaohiem.png",
      lable: "Công ty bảo hiểm",
      link: "https://elife.com.vn",
    },

    {
      image: "assets/user/images/product/Web_VBI.png",
      lable: "Bảo hiểm VBI",
      link: "https://vbict.vn",
    },
  ];
  const banhang = [
    {
      image: "assets/user/images/product/mykat.vn.jpeg",
      lable: "MYKAT ORGANIC",
      link: "https://mykat.vn/",
    },
    {
      image: "assets/user/images/product/ctyson.png",
      lable: "Đại lý sơn",
      link: "https://minhphuongsonhoaviet.com",
    },
    {
      image: "assets/user/images/product/baobi.png",
      lable: "Công ty bao bì niêm phong",
      link: "https://tuiniemphong3th.com",
    },

    {
      image: "assets/user/images/product/Web_Shophang.png",
      lable: "Thời trang bán lẻ - Shop Hằng",
      link: "https://shophang.com.vn",
    },
    {
      image: "assets/user/images/product/minmart.jpg",
      lable: "Siêu thị MinMart",
      link: "https://minmart.com.vn",
    },
  ];
  const chuyennghiep = [
    {
      image: "assets/user/images/product/haivan.png",
      lable: "Hải Vân Trading & Advertising",
      link: "https://haivan.katec.vn",
    },

    {
      image: "assets/user/images/product/Web_bonniekids.png",
      lable: "Website song ngữ Bonniekids",
      link: "https://bonniekids.com.au",
    },
    {
      image: "assets/user/images/product/gena.jpeg",
      lable: "Website Kacaty",
      link: "https://kacaty.vn",
    },

    {
      image: "assets/user/images/product/mykat.jpg",
      lable: "Công ty thiết kế và xây dựng",
      link: "https://mykatcons.vn",
    },
    {
      image: "assets/user/images/product/tocdo5g.com.jpeg",
      lable: "Tốc độ 5G",
      link: "https://tocdo5g.com",
    },
  ];

  useEffect(() => {
    setdataa(doanhnghiep);
    // setshow(true)
    const interval = setInterval(() => {
      setCurrent_day(new Date());
    }, 1000);
    // console.log(current_day-to_day)
    if (interval - to_day < 100000 && interval - to_day > 80000) {
      setshow(true);
    }
  }, []);

  const formclose = () => {
    setshow(false);
  };
  const formimgclose = () => {
    setshowimg(false);
  };
  const form = useRef();
  const formSDT = useRef();
  const sendSDT = (e) => {
    if (formSDT.current.phone.value.length > 9) {
      emailjs
        .sendForm(
          "service_fihg5vx",
          "template_j62qkpn",
          formSDT.current,
          "tt8EBINZkDg6uPMLi"
        )
        .then(
          () => {
            toast(`Đăng ký thành công`, {
              type: "success",
              autoClose: 1000,
              style: { marginTop: "50px" },
            });
            formSDT.current.phone.value = "";
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      toast(`Vui lòng nhập số điện thoại đúng định dạng`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
  };

  const sendEmail = (e) => {
    if (formSDT.current.phone.value.length > 9) {
      emailjs
        .sendForm(
          "service_fihg5vx",
          "template_j62qkpn",
          form.current,
          "tt8EBINZkDg6uPMLi"
        )
        .then(
          () => {
            navigate("/dangkythanhcong");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      toast(`Vui lòng nhập số điện thoại đúng định dạng`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
  };

  return (
    <main>
      <Modal show={showimg} onHide={formimgclose}>
        <Modal.Header closeButton>
          Hình ảnh website {localStorage.getItem("name")}
        </Modal.Header>
        <Modal.Body>
          <i className="mb-3">
            Link Demo{" "}
            <a
              href={localStorage.getItem("link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {localStorage.getItem("link")}
            </a>
          </i>
          <img src={localStorage.getItem("url")} alt="Hình ảnh website" />
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={formclose} className="modal_info">
        <Modal.Header closeButton>
          <h2>Thông tin khách hàng</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            Hãy để lại thông tin
            <br />
            Katec sẽ liên hệ với bạn trong thời gian sớm nhất!
          </p>
          <Form ref={form} onSubmit={() => sendEmail()}>
            <Row>
              <Col>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="user_name"
                  />
                  <Form.Label for="floatingInputClassName">
                    Tên khách hàng<i className="text-danger"></i>
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Sđt"
                    name="phone"
                    required
                  />
                  <Form.Label for="floatingInputClassName">
                    Số điện thoại<i className="text-danger">*</i>
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
            </Row>

            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputClassName"
                placeholder="Tên lớp học"
                name="message"
              />
              <Form.Label for="floatingInputClassName">
                Lời nhắn<i className="text-danger"></i>
              </Form.Label>
              <div className="invalid-feedback"></div>
            </Form.Floating>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => formclose()}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>
          <Button
            type="submit"
            variant="success"
            onClick={() => {
              sendEmail();
            }}
          >
            <i className="fa-solid fa-check"></i> Gửi
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="sidebar" className="top-nav">
        <ul id="sidebar-nav" className="sidebar-nav">
          <li>
            <a href="#">Help</a>
          </li>
          <li>
            <a href="howitworks.html">How it works</a>
          </li>
          <li>
            <a href="#">chamb for Business</a>
          </li>
        </ul>
      </div>

      <div className="page-content-product">
        <div className="main-product">
          <div className="container">
            <div className="row clearfix">
              <center>
                <div className="find-box">
                  <h1>
                    Thiết kế website
                    <br />
                    Nâng tầm giá trị
                  </h1>

                  <Row>
                    <Col md={{ span: 10, offset: 2 }} xs={12}>
                      <h5 className="text-start">
                        <FontAwesomeIcon
                          icon={faStar}
                          beat
                          style={{ color: "#ffbb00" }}
                        />{" "}
                        Katec chuyên thiết kế website trọn gói chuẩn SEO
                        <br />
                        <FontAwesomeIcon
                          icon={faStar}
                          beat
                          style={{ color: "#ffbb00" }}
                        />{" "}
                        Tối ưu chi phí với thời gian bàn giao nhanh chóng
                        <br />
                        <FontAwesomeIcon
                          icon={faStar}
                          beat
                          style={{ color: "#ffbb00" }}
                        />{" "}
                        Tư vấn phát triển theo định hướng kinh doanh của bạn
                        <br />
                      </h5>
                    </Col>
                  </Row>

                  <div className="product-sh ">
                    <Form ref={formSDT} onSubmit={() => sendEmail()}>
                      <Row>
                        <Col md={{ span: 6, offset: 2 }} xs={7}>
                          <Form.Floating>
                            <Form.Control
                              style={{ borderRadius: "50px" }}
                              type="text"
                              // id="floatingInputClassName"
                              placeholder="Tên khách hàng"
                              name="phone"
                            />
                            <Form.Label
                              for="floatingInputClassName"
                              style={{ marginLeft: "10px" }}
                            >
                              Nhập số điện thoại...
                              <i className="text-danger"></i>
                            </Form.Label>
                            <div className="invalid-feedback"></div>
                          </Form.Floating>
                        </Col>
                        <Col md={3} xs={5} style={{ textAlign: "initial" }}>
                          <Button
                            className="text-center"
                            style={{
                              border: "0px",
                              borderRadius: "50px",
                              height: "100%",
                              textAlign: "initial",
                            }}
                            onClick={() => sendSDT()}
                          >
                            {" "}
                            Đăng ký tư vấn
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="cat-main-box">
        <div className="container">
          <div className="row panel-row">
            <div className="col-md-4 col-sm-6 wow fadeIn" data-wow-delay="0.0s">
              <div className="panel panel-default">
                <div className="panel-body">
                  <img
                    src="../../../assets/user/images/company_img.png"
                    className="icon-small"
                    alt=""
                  />
                  <h4>WEB TIÊU CHUẨN</h4>
                  <h5>2.100.000 VNĐ</h5>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Website thiết kế chuẩn Seo. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Miễn phí SSL. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Bảo hành: Vĩnh viễn. <br />
                    <FontAwesomeIcon
                      icon={faDatabase}
                      className="text-success"
                      width={20}
                    />{" "}
                    Dung lượng hosting: 1 GB, 600.000 VNĐ. <br />
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="text-success"
                      width={20}
                    />{" "}
                    Phí duy trì năm thứ 2: 600.000 VNĐ. <br />{" "}
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="text-success"
                      width={20}
                    />{" "}
                    Phí thiết kế: 1.500.000 VNĐ. <br />
                    <FontAwesomeIcon
                      icon={faLaptop}
                      className="text-success"
                      width={20}
                    />{" "}
                    Giao diện theo mẫu có sẵn:{" "}
                    <a
                      href="#doanhnghiep"
                      onClick={() => setdataa(doanhnghiep)}
                    >
                      Xem tại đây.
                    </a>{" "}
                    <br />
                    <FontAwesomeIcon
                      icon={faTags}
                      className="text-success"
                      width={20}
                    />{" "}
                    Khuyến mãi gia hạn hosting: 2 năm tặng 3 tháng, 3 năm tặng 6
                    tháng.
                  </p>
                  <center>
                    <button className="button-62" onClick={() => setshow(true)}>
                      {" "}
                      Đăng ký tư vấn
                    </button>
                  </center>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 wow fadeIn" data-wow-delay="0.0s">
              <div className="panel panel-default">
                <div className="panel-body">
                  <img
                    src="../../../assets/user/images/exciting_img-02.jpg"
                    className="icon-small"
                    alt=""
                  />
                  <h4> WEB BÁN HÀNG</h4>
                  <h5>5.000.000 VNĐ</h5>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Website thiết kế chuẩn Seo. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Miễn phí SSL. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Không giới hạn số lượng sản phẩm. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Tính năng: Bài viết; Sản phẩm; Khuyến mãi; Liên hệ (KH để
                    lại email, SĐT); Đăng ký thành viên; Đơn hàng (Phát sinh đơn
                    hàng sẽ nhận Email và có thể quản lý đơn hàng). <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Bảo hành: Vĩnh viễn. <br />
                    <FontAwesomeIcon
                      icon={faDatabase}
                      className="text-success"
                      width={20}
                    />{" "}
                    Dung lượng hosting: 4 GB. <br />
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="text-success"
                      width={20}
                    />{" "}
                    Phí duy trì năm thứ 2: 2.000.000 VNĐ. <br />{" "}
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="text-success"
                      width={20}
                    />{" "}
                    Phí thiết kế: 3.000.000 VNĐ. <br />
                    <FontAwesomeIcon
                      icon={faLaptop}
                      className="text-success"
                      width={20}
                    />{" "}
                    Giao diện theo mẫu có sẵn:{" "}
                    <a href="#banhang" onClick={() => setdataa(banhang)}>
                      Xem tại đây.
                    </a>{" "}
                    <br />
                    <FontAwesomeIcon
                      icon={faTags}
                      className="text-success"
                      width={20}
                    />{" "}
                    Khuyến mãi gia hạn hosting: 2 năm tặng 3 tháng, 3 năm tặng 6
                    tháng.
                  </p>
                  <center>
                    <button className="button-62" onClick={() => setshow(true)}>
                      {" "}
                      Đăng ký tư vấn
                    </button>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 wow fadeIn" data-wow-delay="0.0s">
              <div className="panel panel-default">
                <div className="panel-body">
                  <img
                    src="../../../assets/user/images/system_img.png"
                    className="icon-small"
                    alt=""
                  />
                  <h4>WEB THEO YÊU CẦU</h4>
                  <h5>Giá liên hệ</h5>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Website thiết kế chuẩn Seo. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Miễn phí SSL. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Tính năng: Thiết kế theo yêu cầu. <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                      width={20}
                    />{" "}
                    Bảo hành: Vĩnh viễn. <br />
                    <FontAwesomeIcon
                      icon={faDatabase}
                      className="text-success"
                      width={20}
                    />{" "}
                    Dung lượng hosting: 10 GB. <br />
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="text-success"
                      width={20}
                    />{" "}
                    Phí duy trì năm thứ 2: 3.600.000 VNĐ . <br />
                    <FontAwesomeIcon
                      icon={faLaptop}
                      className="text-success"
                      width={20}
                    />{" "}
                    Giao diện: Thiết kế theo yêu cầu.
                    <br />
                    <FontAwesomeIcon
                      icon={faTags}
                      className="text-success"
                      width={20}
                    />{" "}
                    Khuyến mãi gia hạn hosting: 2 năm tặng 3 tháng, 3 năm tặng 6
                    tháng.
                  </p>
                  <center>
                    <button className="button-62" onClick={() => setshow(true)}>
                      {" "}
                      Đăng ký tư vấn
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="start-free-box mb-5">
        <div className="container">
          <div className="row">
            <div className="main-start-box">
              <div className="bg_img_left">
                {/* <img src="../../../assets/user/images/bg_img1.png" alt="#" /> */}
              </div>
              <div className="container">
                <div className="buyer-box clearfix">
                  <Row>
                    <div
                      className="col-md-6 col-sm-6 wow fadeIn"
                      data-wow-delay="0.2s"
                    >
                      <div className="left-buyer">
                        <img
                          className="img-responsive"
                          src="../../../assets/user/images/webSEO.png"
                          alt="#"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-sm-6 wow fadeIn"
                      data-wow-delay="0.4s"
                    >
                      <div className="right-buyer">
                        <h1>Website chuẩn SEO </h1>
                        <h2>
                          Gia tăng sự nhận diện thương hiệu
                          <br />
                          {/* <span>Tối đa hóa lợi nhuận với chi phí</span> */}
                        </h2>

                        <p>
                          Thiết kế website chuẩn SEO là một trong những yếu tố
                          quan trọng trong việc phát triển một trang web thành
                          công. Với sự phát triển của công nghệ và sự cạnh tranh
                          ngày càng gay gắt trong thị trường kinh doanh trực
                          tuyến, việc có một trang web chuẩn SEO không chỉ giúp
                          tăng độ tin cậy và uy tín của trang web mà còn giúp
                          thu hút lượng khách hàng tiềm năng đến với trang web
                          của bạn.
                        </p>
                        <button
                          className="button-62"
                          onClick={() => setshow(true)}
                        >
                          {" "}
                          Đăng ký tư vấn
                        </button>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="bg_img_right"></div>
            <div className="main-start-box">
              <div className="container">
                <div className="supplier clearfix">
                  <Row>
                    <div className="col-md-5 col-sm-6">
                      <div className="left-supplier">
                        <h1>Giao diện</h1>
                        <h2>
                          Thiết kế đẹp mắt
                          <br />
                          <span>Dễ sử dụng</span>
                        </h2>
                        <p>
                          Thiết kế chuyên nghiệp, khoa học, đảm bảo chất lượng.
                          Có thể thiết kế theo nhu cầu và phong cách riêng.
                          Tương thích với tất cả thiết bị máy tính, tablet, điện
                          thoại. Tốc độ load siêu nhanh.{" "}
                        </p>
                        <button
                          className="button-62"
                          onClick={() => setshow(true)}
                        >
                          {" "}
                          Đăng ký tư vấn
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="right-supplier">
                        <img
                          className="img-responsive"
                          src="../../../assets/user/images/supplier-pc-img.png"
                          alt="#"
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="main-start-box">
              <div className="bg_img_left"></div>

              <div className="container">
                <div className="buyer-box clearfix">
                  <Row>
                    <div
                      className="col-md-6 col-sm-6 wow fadeIn"
                      data-wow-delay="0.2s"
                    >
                      <div className="left-buyer">
                        <img
                          className="img-responsive"
                          src="../../../assets/user/images/webcategoty.png"
                          alt="#"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-sm-6 wow fadeIn"
                      data-wow-delay="0.4s"
                    >
                      <div className="right-buyer">
                        <h1>Thiết kế theo lĩnh vực</h1>
                        <h2>
                          Website chuyên nghiệp, phù hợp với nhu cầu kinh doanh
                        </h2>
                        <p>
                          Thiết kế website chuyên nghiệp theo lĩnh vực là quá
                          trình tạo ra một trang web đẹp, chuyên nghiệp và hiệu
                          quả. katec cần có một kế hoạch thiết kế chi tiết và rỏ
                          ràng. Xác định mục tiêu của khách hàng, đảm bảo phù
                          hợp với mục tiêu và yêu cầu của khách hàng.
                        </p>
                        <button
                          className="button-62"
                          onClick={() => setshow(true)}
                        >
                          {" "}
                          Đăng ký tư vấn
                        </button>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="start-free-box mb-5">
        <div className="container">
          <h4
            className="text-center"
            style={{
              textTransform: "uppercase",
              padding: "10px",
              fontSize: "28px",
              fontWeight: "bold",
              color: "#12345b",
            }}
          >
            Quy trình thiết kế webiste
          </h4>
          <div className="flow">
            <Row>
              <Col>
                <div class="small-box wow fadeIn" data-wow-delay="0.2s">
                  <div class="how-text-left">1</div>
                  <div class="how-dit-right">
                    <h2>TIẾP NHẬN YÊU CẦU</h2>
                    <h3>Gặp gỡ khách hàng</h3>
                  </div>
                </div>
              </Col>
              <Col>
                <div class="how-text-left">2</div>
                <div class="how-dit-right">
                  <h2>THIẾT KẾ</h2>
                  <h3>
                    Thiết kế giao diện <br /> và <span>Chức năng</span>
                  </h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div class="how-text-left">3</div>
                <div class="how-dit-right">
                  <h2>LẬP TRÌNH</h2>
                  <h3>
                    Tiến hành
                    <br />
                    <span>lập trình</span>
                  </h3>
                </div>
              </Col>
              <Col>
                <div class="how-text-left">4</div>
                <div class="how-dit-right">
                  <h2>KIỂM THỬ</h2>
                  <h3>
                    Kiểm tra <br />
                    <span>chất lượng website </span>
                  </h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div class="how-text-left">5</div>
                <div class="how-dit-right">
                  <h2>BÀN GIAO</h2>
                  <h3>
                    Hướng dẫn <br />
                    và <span>bàn giao</span>
                  </h3>
                </div>
              </Col>
              <Col>
                <div class="how-text-left">6</div>
                <div class="how-dit-right">
                  <h2>ĐỒNG HÀNH</h2>
                  <h3>
                    Hỗ trợ <br />
                    và <span>đồng hành</span>
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Container id="product_home">
        <h4
          className="text-center"
          style={{
            textTransform: "uppercase",
            padding: "10px",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#12345b",
          }}
        >
          Sản phẩm của katec
        </h4>

        <Row className="product_item" id="doanhnghiep">
          <div class="box-title">
            <div class="box-title-name">Website Tiêu Chuẩn</div>
          </div>

          {doanhnghiep?.map((item) => {
            return (
              <div className="col-lg-3 col-sm-6 col-md-3">
                <a
                  onClick={() => {
                    setshowimg(true);
                    localStorage.setItem("link", item.link);

                    localStorage.setItem("url", item.image);
                    localStorage.setItem("name", item.lable);
                    // showImage(item.image)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="contai">
                    <div className="box-img">
                      <img src={item.image} alt="" />
                    </div>
                    <h4>{item.lable}</h4>
                  </div>
                </a>
              </div>
            );
          })}
        </Row>

        <Row className="product_item" id="banhang">
          <div class="box-title">
            <div class="box-title-name">Website Bán Hàng</div>
          </div>

          {banhang?.map((item) => {
            return (
              <div className="col-lg-3 col-sm-6 col-md-3">
                <a
                  onClick={() => {
                    setshowimg(true);
                    localStorage.setItem("link", item.link);

                    localStorage.setItem("url", item.image);
                    localStorage.setItem("name", item.lable);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="contai">
                    <div className="box-img">
                      <img src={item.image} alt="" />
                    </div>
                    <h4>{item.lable}</h4>
                  </div>
                </a>
              </div>
            );
          })}
        </Row>

        <Row className="product_item" id="chuyennghiep">
          <div class="box-title">
            <div class="box-title-name">Website Theo Yêu cầu</div>
          </div>

          {chuyennghiep?.map((item) => {
            return (
              <div className="col-lg-3 col-sm-6 col-md-3">
                <a
                  onClick={() => {
                    setshowimg(true);
                    localStorage.setItem("link", item.link);
                    localStorage.setItem("url", item.image);
                    localStorage.setItem("name", item.lable);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="contai">
                    <div className="box-img">
                      <img src={item.image} alt="" />
                    </div>
                    <h4>{item.lable}</h4>
                  </div>
                </a>
              </div>
            );
          })}
        </Row>
      </Container>
    </main>
  );
}

export default HomeIndex;
